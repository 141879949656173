<template>
  <div class="auth-container">
    <div class="auth-form">
      <div class="auth-form__header">
        <span class="active-header">{{ $t('links.entrance') }}</span>
        <span @click="$router.push('/register')">{{ $t('links.registration') }}</span>
      </div>

      <b-form @submit="onSubmit" class="auth-form__body">
        <AppInput
          ref="email"
          name="email"
          :label="$t('label.email')"
          :invalidFeedback="getErrorMessage('email')"
          :state="$v.form.email.$error || error ? false : null"
          @blur="setField"
        />
        <AppInput
          type="password"
          ref="password"
          name="password"
          :label="$t('placeholders.password')"
          :invalidFeedback="getErrorMessage('password') || error"
          :state="$v.form.password.$error || error ? false : null"
          @blur="setField"
        />
        <div class="recover">
          <span class="link" @click="$router.push('/forgot-password')">{{
            $t('buttonLabels.forgotPassword')
          }}</span>
        </div>
        <b-button class="mt-3" type="submit" variant="primary" :disabled="loading">
          <span v-if="!loading">{{ $t('buttonLabels.login') }}</span>
          <span v-else>
            <b-spinner small type="grow"></b-spinner>
            {{ $t('label.processing') }}
          </span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import focusOnValidation from '@/mixins/focusOnValidation';
import AppInput from '@/components/AppInput.vue';

export default {
  name: 'AuthorizationForm',
  components: { AppInput },
  mixins: [focusOnValidation],
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    loading: false,
    error: null,
    authMode: true,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    validation() {
      return this.form.lastName.length > 4 && this.form.lastName.length < 13;
    },
  },
  methods: {
    setField(e) {
      const fieldName = e.target.name;
      this.form[fieldName] = e.target.value;
      this.$v.form[fieldName].$touch();
      this.resetCustomErrors();
    },

    resetCustomErrors() {
      this.emailError = '';
      this.serverError = '';
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(event) {
      event.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const loginInput = {
        username: this.form.email,
        password: this.form.password,
      };
      this.loading = true;
      this.error = null;
      this.$store
        .dispatch('auth/authorization', loginInput)
        .then(async () => {
          const subscribeLink = localStorage.getItem('subscribe-link');
          const subscribeManager = localStorage.getItem('subscribe-manager');
          if (subscribeLink) {
            localStorage.removeItem('subscribe-link');
            await this.$router.push(subscribeLink);
          } else if (subscribeManager) {
            const { id, token } = JSON.parse(subscribeManager);
            localStorage.removeItem('subscribe-manager');
            await this.$router.push({ name: 'SubscribeManager', params: { id }, query: { token } });
          } else {
            this.$router.push('/programs').catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.status === 400) {
            this.error = this.$t('errorMessages.passwordEmailNotFound');
          } else if (error.response?.status === 404) {
            this.error = this.$t('errorMessages.userNotFound');
          } else {
            this.error = this.$t('errorMessages.somethingWentWrong');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

::v-deep .form-group {
  margin-bottom: 1.25rem;
}

::v-deep input.form-control:focus {
  color: #f2f2f2;
  box-shadow: none;
}

::v-deep .btn {
  margin: 0.625rem 0 0 !important;
  padding: 0.875rem 4.458rem;
}
</style>
